<template>
  <div>
    <vue-slider ref="slider" v-model="getSliderOptions.value" v-bind="getSliderOptions"></vue-slider>
  </div>
</template>
<script>
import vueSlider from 'vue-slider-component'
// import { EventBus } from './../main.js'

export default {
  props: ['timeline'],
  data () {
    return {
      defaultOptions: {
        value: [0, 0],
        min: 4,
        max: 18,
        disabled: false,
        piecewiseLabel: true,
        tooltip: false,
        lazy: true
      }
    }
  },
  computed: {
    getSliderOptions () {
      const sliderOptions = this.$formatter.cloneVariable(this.defaultOptions)
      sliderOptions.min = 4
      sliderOptions.piecewiseLabel = true
      sliderOptions.disabled = true
      sliderOptions.tooltip = false

      const start = isFinite(this.timeline.start) ? this.timeline.start : 0
      const end = isFinite(this.timeline.end) ? this.timeline.end : 0
      if (start !== '' || end !== '') {
        if (sliderOptions.max < start || sliderOptions.max < end) {
          sliderOptions.max = Math.ceil((sliderOptions.max <= 24 ? end : end + 2))
        }
        if (sliderOptions.min > start || sliderOptions.min > end) {
          sliderOptions.min = Math.floor((sliderOptions.min >= 0 ? start : start - 2))
        }
        sliderOptions.value = [start, end]
      } else {
        sliderOptions.value = [4, 4]
        sliderOptions.max = 18
      }
      return sliderOptions
    }
  },
  /*  updated() {
        EventBus.$on('tabChanged', () => {
          this.$nextTick(function () {
            if (this.$refs.slider) {
              this.$refs.slider.refresh();
            }
        });
      });
    }, */
  components: {
    'vue-slider': vueSlider
  }
}
</script>
